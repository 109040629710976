import { useMutation } from '@tanstack/react-query'
import { useGlobalPageState } from '@kaliber/use-global-page-state'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getDatabase, push, update, ref, serverTimestamp, get } from 'firebase/database'
import { ref as storageRef, getStorage, uploadBytes } from 'firebase/storage'
import { useForm, object, useFormField } from '@kaliber/forms'
import { useClientConfig } from '/machinery/ClientConfig'
import { maxLength, optional } from '@kaliber/forms/validation'
import { useElementSize } from '@kaliber/use-element-size'
import { useReportError } from '/machinery/ReportError'
import { getGoogleAnalyticsId } from '/machinery/getGoogleAnalyticsId'
import { useFirebaseApp } from '/machinery/FirebaseAppProvider'
import { useTranslate } from '/machinery/I18n'
import { fileSize, fileExtension, checked, linkedInUrl, email, required } from '/machinery/customValidation'
import { pushToDataLayer, mapJobForDataLayer } from '/machinery/tracking/pushToDataLayer'
import { FormFieldsWithSteps, FormFields } from '/features/buildingBlocks/FormFields'
import { SubheadingSm } from '/features/buildingBlocks/Subheading'
import { HeadingLg } from '/features/buildingBlocks/Heading'
import { TextSm } from '/features/buildingBlocks/Text'
import { Button } from '/features/buildingBlocks/Button'
import { BreathingCircles } from '/features/pageOnly/screensaver/BreathingCircles'
import { useOpenScreensaver } from '/features/pageOnly/screensaver/screenSaverControls'
import { routeMap } from '/routeMap'

import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './Form.css'
import { LinkUnderline } from './Link'
import { useLocationMatch } from '@kaliber/routing'

// When changing filesize also change the firebase storage rules to match this (all environments)
const maxFileSize = 2 * 1024 * 1024 // 2 MB
const unpublishedJobMessage = 'unpublished-job-message'

const validationPerFieldType = {
  INPUT_TEXT: [maxLength(128)],
  TEXTAREA: [maxLength(3600)],
}

export function Form({ job, title, enablePortfolioField, utm, isEmployee }) {
  const trackedBeginCheckoutRef = React.useRef(false)
  const formRef = React.useRef(false)
  const { __ } = useTranslate()
  const firebaseApp = useFirebaseApp()
  const reportError = useReportError()
  const { acceptedFileTypes, ga4Id } = useClientConfig()
  const { screeningQuestions = [] } = job
  const resumeFieldRequired = job.locationType === 'offices'

  const extensions = acceptedFileTypes.map(x => x.extension)

  const [hasApplied = false, setHasApplied] = useGlobalPageState('has-applied-state')

  const { data: message, mutate: sendApplication, isPending: isLoading, isError, isSuccess } = useMutation({
    mutationFn: handleSendApplication,
    onError: reportError,
    onSuccess: () => {
      setHasApplied(true)
      pushToDataLayer({ event: 'purchase' })
    }
  })

  const isUnpublishedJobMessage = message === unpublishedJobMessage

  const {
    questionsInitialValues,
    screeningQuestionsWithoutInformation,
    hasScreeningQuestions
  } = useScreeningQuestions(screeningQuestions)

  const { form, submit } = useForm({
    initialValues: {
      default: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        link: '',
        ...(enablePortfolioField && { linkPortfolio: '' }),
        resume: null,
        skillsAmbitions: '',
      },
      ...(hasScreeningQuestions && {
        answers: questionsInitialValues,
      }),
      consentPrivacy: false,
      consentCrm: false,
    },
    fields: {
      default: object({
        firstName: [required, maxLength(128)],
        lastName: [required, maxLength(128)],
        email: [required, email, maxLength(128)],
        phoneNumber: [required, maxLength(128)],
        link: [linkedInUrl, maxLength(128)],
        ...(enablePortfolioField && { linkPortfolio: [maxLength(128)] }),
        resume: [
          ...(resumeFieldRequired ? [required] : []),
          fileSize(maxFileSize),
          fileExtension(extensions)
        ],
        skillsAmbitions: [maxLength(3600)],
      }),
      ...(hasScreeningQuestions && {
        answers: object(questionsFields(screeningQuestionsWithoutInformation)),
      }),
      consentPrivacy: [required, checked],
      consentCrm: optional,
    },
    onSubmit: handleSubmit
  })

  if (isSuccess && !isUnpublishedJobMessage) return (
    <ThankYouMessage firstNameField={form.fields.default.fields.firstName} jobTitle={job.title} />
  )

  return (
    <Base>
      <div ref={formRef} className={styles.heading}>
        <SubheadingSm
          title={__`job-detail-application-form-label`}
          layoutClassName={styles.subheadingLayout}
        />
        <HeadingLg h='3' layoutClassName={styles.headingLayout} {...{ title }} />
      </div>

      <form onSubmit={submit} className={styles.form} onChange={handleCheckout} noValidate>
        {hasScreeningQuestions
          ? <FormFieldsWithSteps onScrollToForm={handleScrollToForm} {...{ form, extensions, isLoading, screeningQuestions, resumeFieldRequired, enablePortfolioField }} />
          : <FormFields onScrollToForm={handleScrollToForm} {...{ form, isLoading, extensions, resumeFieldRequired, enablePortfolioField }} />}

        {/* TODO: Add friendlier message for isUnpublishedJobMessage (RITC-1302) */}
        {isError && <ErrorMessage message={__`application-form-error-message`} />}
        {isUnpublishedJobMessage && <UnpublishedMessage />}
      </form>
    </Base>
  )

  function handleScrollToForm() {
    const top = window.scrollY + formRef?.current.getBoundingClientRect().top - 100
    window.scrollTo(0, top)
  }

  function handleSubmit({ invalid, value }) {
    if (invalid) return
    sendApplication(value)
  }

  async function handleSendApplication(value) {
    const { answers, consentPrivacy, consentCrm } = value
    const { resume, ...restFormValues } = value.default

    const { files, storageRefs } = await getFilesInfo(resume)

    const { user: { uid } } = await signInAnonymously(getAuth(firebaseApp))

    const database = getDatabase(firebaseApp)
    const currentJobStatus = await getCurrentJobStatus(database)
    if (currentJobStatus && currentJobStatus !== 'PUBLISHED') return unpublishedJobMessage

    if (files) await storeFiles({ uid, files })

    const clientId = await getGoogleAnalyticsId({ ga4Id, identifier: 'client_id' }).catch(reportError)
    const sessionId = await getGoogleAnalyticsId({ ga4Id, identifier: 'session_id' }).catch(reportError)

    const result = await push(ref(database, 'services/application-processing-service/queue'), {
      jobAdId: job.jobAdId,
      jobId: job.jobId,
      uuid: job.uuid,
      userUid: uid,
      formSubmitDate: serverTimestamp(),
      storageRefs,
      isEmployee,
      formValues: {
        consentPrivacy,
        consentCrm,
        ...restFormValues,
        ...(answers && { answers: atsFormValuesData(answers, screeningQuestionsWithoutInformation) })
      },
      ...(utm && { utm }),
      ...(clientId && { clientId }),
      ...(sessionId && { sessionId }),
    })

    if (!clientId || !sessionId) return

    await update(ref(database, `services/application-processing-service/jobInfo/${result.key}`), {
      ...getJobtrackingData(job),
    }).catch(reportError)
  }

  async function getFilesInfo(resume) {
    const resumeFile = resume instanceof File && await resume.arrayBuffer()

    if (!resumeFile?.byteLength)
      return { files: null, storageRefs: null }

    const extension = getExtension(resume.name)

    return {
      files: { resume: { file: resumeFile, extension } },
      storageRefs: { resume: { extension } }
    }
  }

  async function storeFiles({ uid, files }) {
    const storage = getStorage(firebaseApp)

    await Promise.all(
      Object.entries(files).map(
        async ([fieldName, { file, extension }]) => {
          const uploadRef = storageRef(storage, `/uploads/${uid}/${job.uuid}/${fieldName}.${extension}`)
          await uploadBytes(uploadRef, file)
        }
      )
    )
  }

  function handleCheckout() {
    if (!trackedBeginCheckoutRef.current) {
      trackedBeginCheckoutRef.current = true
      pushToDataLayer({ event: 'begin_checkout' })
    }
  }

  async function getCurrentJobStatus(database) {
    const shopJobStatus = (await get(ref(database, `/shopJobs/${job.jobAdId}/status`))).val()
    const officeJobStatus = (await get(ref(database, `/officeJobs/${job.jobAdId}/status`))).val()
    return officeJobStatus ?? shopJobStatus
  }
}

function questionsFields(questions) {
  return questions.reduce(
    (result, question) => {
      const [field] =  question.fields
      const baseValidation = field.required
        ? field.type === 'CHECKBOX' || field.type === 'MULTI_SELECT'
          ? [required, checked]
          : [required]
        : []

      const extraRules = validationPerFieldType[field.type]
      const validation = baseValidation.concat(extraRules ?? [])

      return { ...result, [question.id]: validation }
    },
    {}
  )
}

function ErrorMessage({ message }) {
  return <div>{message}</div>
}

function UnpublishedMessage() {
  const { __ } = useTranslate()
  const { params } = useLocationMatch()

  return (
    <div>
      {__`application-form-unpublished-message-start`}
      <LinkUnderline
        href={routeMap.app.jobs.overview({ language: params.language })}
        dataX='link-to-jobsOverview'
      >
        {__`application-form-unpublished-message-link-label`}
      </LinkUnderline>
      {__`application-form-unpublished-message-end`}
    </div>
  )
}

function ThankYouMessage({ firstNameField, jobTitle }) {
  const { state: { value: name } } = useFormField(firstNameField)
  const { ref: sizeRef, size } = useElementSize()
  const showScreensaver = useOpenScreensaver()
  const { __ } = useTranslate()

  const thankYouHeading = __({ name })`form-ThankYou-thank-you`
  const succesMessage = __({ title: jobTitle })`form-ThankYou-you-succesfully-submitted`

  return (
    <div className={styles.componentThankYouMessage} data-style-context={cssStyleContext.contextClay}>
      <SubheadingSm title={thankYouHeading} layoutClassName={styles.subheadingLayout} />
      <HeadingLg h='3' title={succesMessage} layoutClassName={styles.headingLayout} />
      <TextSm text={__`form-ThankYou-take-a-deep-breath`} layoutClassName={styles.textLayout} />
      <Button onClick={showScreensaver} dataX='click-to-meditate'>{__`form-thankYou-button-label-take-a-moment`}</Button>

      <div className={styles.breathingCirclesContainer}>
        <div ref={sizeRef} className={styles.breathingCircles}>
          {Boolean(size.width && size.height) && (
            <BreathingCircles width={size.width} height={size.height} />
          )}
        </div>
      </div>
    </div>
  )
}

function Base({ children }) {
  return (
    <div className={styles.componentBase} data-style-context={cssStyleContext.contextGray} data-x='application-form'>
      {children}
    </div>
  )
}

function getQuestionsInitialValues(questions) {
  return Object.fromEntries(
    questions.map(question => {
      const [field] =  question.fields
      return [question.id, field.type === 'CHECKBOX' ? false : '' ]
    })
  )
}

function getExtension(name) { return name.split('.').slice(-1).join() }

function atsFormValuesData(answers, screeningQuestionsWithoutInformation) {
  return Object.fromEntries(
    Object.entries(answers).map(([id, value]) => {
      const question = screeningQuestionsWithoutInformation.find(x => x.id === id)
      const [field] = question.fields

      return [id, {
        id: field.id,
        values: Array.isArray(value) ? value : [value]
      }]
    })
  )
}

function questionsWithoutInformation(screeningQuestions) {
  return screeningQuestions.filter(question => !question.fields.some(x => x.type === 'INFORMATION'))
}

function useScreeningQuestions(screeningQuestions) {
  const { questionsInitialValues, screeningQuestionsWithoutInformation } = React.useMemo(
    () => {
      const screeningQuestionsWithoutInformation = questionsWithoutInformation(screeningQuestions)

      return {
        questionsInitialValues: getQuestionsInitialValues(screeningQuestionsWithoutInformation),
        screeningQuestionsWithoutInformation,
      }
    },
    [screeningQuestions]
  )

  return {
    questionsInitialValues,
    screeningQuestionsWithoutInformation,
    hasScreeningQuestions: Boolean(screeningQuestionsWithoutInformation?.length)
  }
}

function getJobtrackingData(job) {
  const x = mapJobForDataLayer(job)

  /**
    * If something changes in candidateTracking, also change it in:
    * createFirebaseRules.js : candidateTracking
    * canidate-tracking-service.js : handleApplicationStatusUpdate
    * pushToDatalayer : mapJobForDataLayer
  */

  return {
    id: x.id,
    jobid: x.jobid,
    refnumber: x.refnumber,
    title: x.title,
    brand: x.brand,
    type: x.type,
    datestart: x.datestart,
    language: x.language,
    experience: x.experience,
    location: {
      country: x.location.country,
      city: x.location.city,
      type: x.location.type,
      postalcode: x.location.postalcode,
      address: x.location.address,
      lat: x.location.lat,
      long: x.location.long,
    },
    role: x.role ?? null,
    department: x.department ?? null,
    typeofemployment: x.typeofemployment ?? null,
    hoursperweekmin: x.hoursperweekmin ?? null,
    hoursperweekmax: x.hoursperweekmax ?? null,
  }
}
